// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$screen-md: 						992px !default;
$screen-md-min: 				$screen-md !default;
$grid-float-breakpoint: $screen-md !default;

// Colors
$brand-primary:       rgb(125, 125, 125); /* this is a fallback - primary colour defined in dynamic.css.php through wordpress*/
$text-colour:		      rgb(89, 93, 95);
$white:					      rgb(255,255,255);
$rgba-black:			    rgba(0,0,0,0.7);
$black:						    rgb(0,0,0);
$rgba-primary:				rgba(63, 169, 245, 0.7);
$dark-grey:           rgb(85,85,85);
$grey:							  rgb(155, 155, 154);
$light-grey:					rgb(237,239,238);
$v-light-grey:				rgb(242,242,242);
$e-light-grey:				#f8f8f8;
$rgba-white:					rgba(255, 255, 255, 0.1);
$rgba-white-75:				rgba(255,255,255,0.75);

// Fonts
$sans-serif:					'Roboto', sans-serif;
$sans-serif-alt:			$sans-serif;

$column-count:				3;
$column-width:				300px;