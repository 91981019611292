::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #FFF;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #FFF;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #FFF;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #FFF;
  }